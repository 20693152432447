import { createContext, useContext, useEffect, useState } from 'react';
import { usePublicAuthApiClient } from '../api/PublicAuthApiClient';
import { useHttpClient } from '../api/HttpClientContext';

const authContext = createContext<AuthContextState | null>(null);

export interface AuthSession {
	userId: string;
	email: string;
	token: string;
}

interface AuthContextState {
	session: AuthSession | null;
	setSession(session: AuthSession | null): void;
}

const localStorageKey = '__auth';

export function useAuthContextState(): AuthContextState {
	const context = useContext(authContext);
	if (!context) {
		throw new Error('Cannot find AuthContext');
	}
	return context;
}

export function useIsAuthenticated(): boolean {
	const context = useAuthContextState();
	return context.session !== null;
}

export function useSession(): AuthSession {
	const state = useAuthContextState();
	if (!state.session) {
		throw new Error('Not authenticated');
	}
	return state.session;
}

export interface AuthContextProps {
	children: React.ReactNode;
	initialSession?: AuthSession | null;
}

export function AuthContext(props: AuthContextProps) {
	const httpClient = useHttpClient();
	const apiClient = usePublicAuthApiClient();
	const [session, reactSetSession] = useState<AuthSession | null>(() =>
		props.initialSession === undefined ? tryReadStorage() : props.initialSession
	);

	useEffect(() => {
		function handler() {
			setSession(null);
		}

		httpClient.onUnauthorizedError = handler;
		return () => {
			httpClient.onUnauthorizedError = handler;
		};
	}, [httpClient]);

	useEffect(() => {
		if (!session) {
			return;
		}
		const iv = setInterval(async () => {
			const currentToken = session.token;
			try {
				const response = await apiClient.refreshToken({
					token: currentToken
				});
				session.token = response.token;
				updateStorage(session);
			} catch (e) {
				const err = (e as Error).message ?? String(e);
				console.error(`Could not refresh token: ${err}`);
			}
		}, 60_000);

		return () => clearInterval(iv);
	}, [session, apiClient]);

	function setSession(session: AuthSession | null) {
		reactSetSession(session);
		updateStorage(session);
	}

	return <authContext.Provider value={{ session, setSession }}>{props.children}</authContext.Provider>;
}

function tryReadStorage(): AuthSession | null {
	const value = window.localStorage[localStorageKey];
	if (value) {
		return JSON.parse(value);
	} else {
		return null;
	}
}

function updateStorage(session: AuthSession | null) {
	if (session) {
		window.localStorage[localStorageKey] = JSON.stringify(session);
	} else {
		window.localStorage.removeItem(localStorageKey);
	}
}
