import { useEffect, useState } from 'react';
import { DefaultLayout } from '../../components/layout/DefaultLayout';
import { Dashboard, DashboardRowGrid, DashboardPanel } from '../../components/theme/dashboard/Dashboard';
import { LoadingDefaultLayout } from '../../components/layout/LoadingDefaultLayout';
import { ErrorDefaultLayout } from '../../components/layout/ErrorDefaultLayout';
import { HttpClient } from '../../api/HttpClient';
import { useCreditsApiClient } from '../../api/CreditsApiClient';
import { CurrentUsage } from '../../components/theme/dashboard/CurrentUsage';
import { useTraceApiClient } from '../../api/TraceApiClient';
import { CreditUsageDto, TraceStatsDto } from '@visiopilot/admin-model';
import { Chart } from './Chart';
import { WidgetScript } from './WidgetScript';
import { useSession } from '../../auth/AuthContext';

const DAY_TIME_RANGE = 86400000;
const WEEK_TIME_RANGE = DAY_TIME_RANGE * 7;
const ATTACH_WIDGET = false;

interface DashboardRouteData {
	fromTime: number;
	usage: CreditUsageDto;
	stats: TraceStatsDto[];
}

export function DashboardRoute() {
	const session = useSession();
	const creditsApiClient = useCreditsApiClient();
	const traceApiClient = useTraceApiClient();
	const [data, setData] = useState<DashboardRouteData | null>(null);
	const widgetId = session.userId;

	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function load() {
			try {
				const now = Date.now();
				let fromTime = now - WEEK_TIME_RANGE;
				fromTime = fromTime - (fromTime % DAY_TIME_RANGE);
				const [r1, r2] = await Promise.all([creditsApiClient.getUsage(), traceApiClient.getTraceStats({ fromTime })]);

				setData({
					fromTime,
					usage: r1.usage,
					stats: r2.stats
				});
			} catch (e) {
				setError(HttpClient.readError(e));
			}
		}

		load();
	}, [creditsApiClient, traceApiClient]);

	async function onBuyCreditsRequested(packName: string) {
		const link = await creditsApiClient.getPurchaseLink();
		window.location.href = link.links[packName];
	}

	if (error) {
		return <ErrorDefaultLayout error={error} />;
	}
	if (!data) {
		return <LoadingDefaultLayout />;
	}

	return (
		<DefaultLayout>
			<Dashboard>
				<CurrentUsage usage={data.usage} onBuyCreditsRequested={onBuyCreditsRequested} />

				<DashboardRowGrid>
					<DashboardPanel title="Credits">
						<Chart fromTime={data.fromTime} traces={data.stats} field="credits" />
					</DashboardPanel>
				</DashboardRowGrid>
			</Dashboard>

			{ATTACH_WIDGET && <WidgetScript widgetId={widgetId} />}
		</DefaultLayout>
	);
}
