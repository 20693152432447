import { CenteredBox } from '../layout/CenteredBox';
import { PageTitle } from '../layout/PageTitle';

export function Dashboard(props: { children: React.ReactNode }) {
	return (
		<CenteredBox>
			<PageTitle title="Dashboard"> </PageTitle>
			{props.children}
		</CenteredBox>
	);
}

export function DashboardRowGrid(props: { children: React.ReactNode }) {
	return <div className="grid gap-4 py-4 mx-4">{props.children}</div>;
}

export function DashboardPanel(props: { children: React.ReactNode; title: string }) {
	return (
		<div className="p-2.5 border border-gray-300/80 rounded-md">
			<div className="mb-3">{props.title}</div>
			{props.children}
		</div>
	);
}

export interface DashboardStatPanelProps {
	title: string;
	value: string;
	description: string;
}

export function DashboardStatPanel(props: DashboardStatPanelProps) {
	return (
		<DashboardPanel title={props.title}>
			<div className="text-6xl font-bold text-blue-700">{props.value}</div>
			<div className="mt-1 text-sm text-gray-400">{props.description}</div>
		</DashboardPanel>
	);
}
